import React from "react";
import { Link } from "gatsby";

import logo from "@img/logo.png";
import facebook from "@img/social/facebook.svg";
import instagram from "@img/social/instagram.svg";
import twitter from "@img/social/twitter.svg";
import vimeo from "@img/social/vimeo.svg";

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer">
        <img src={logo} alt="Litigium Corp" className="logo" />
        <section className="menu">
          <ul className="menu-list">
            <li>
              <Link to="/" className="navbar-item">
                Inicio
              </Link>
            </li>
            <li>
              <Link className="navbar-item" to="/about">
                Casos de éxito
              </Link>
            </li>
            <li>
              <Link className="navbar-item" to="/contact/examples">
                Servicios
              </Link>
            </li>
            <li>
              <a
                className="navbar-item"
                href="/admin/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Admin
              </a>
            </li>
          </ul>
          <ul className="menu-list">
            <li>
              <Link className="navbar-item" to="/blog">
                Noticias
              </Link>
            </li>
            <li>
              <Link className="navbar-item" to="/contact">
                Nosotros
              </Link>
            </li>
          </ul>
        </section>
        <section className="social">
          <a title="facebook" href="https://facebook.com">
            <img
              src={facebook}
              alt="Facebook"
              style={{ width: "1em", height: "1em" }}
            />
          </a>
          <a title="twitter" href="https://twitter.com">
            <img
              className="fas fa-lg"
              src={twitter}
              alt="Twitter"
              style={{ width: "1em", height: "1em" }}
            />
          </a>
          <a title="instagram" href="https://instagram.com">
            <img
              src={instagram}
              alt="Instagram"
              style={{ width: "1em", height: "1em" }}
            />
          </a>
          <a title="vimeo" href="https://vimeo.com">
            <img
              src={vimeo}
              alt="Vimeo"
              style={{ width: "1em", height: "1em" }}
            />
          </a>
        </section>
      </footer>
    );
  }
};

export default Footer;
