import React from "react";
import { Link } from "gatsby";
import logo from "@img/logo.png";

const Navbar = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      navBarActiveClass: "",
    };
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: "active",
            })
          : this.setState({
              navBarActiveClass: "",
            });
      }
    );
  };

  render() {
    return (
      <nav className="navbar" role="navigation" aria-label="main-navigation">
        <Link to="/" className="navbar-item" title="Logo">
          <img src={logo} alt="Litigium Corp" className="logo" />
        </Link>
        <div
          id="navMenu"
          className={`navbar-menu ${this.state.navBarActiveClass}`}
        >
          <Link className="navbar-item" to="/contact" activeClassName="current">
            Casos de éxito
          </Link>
          <Link
            className="navbar-item"
            to="/contact/examples"
            activeClassName="current"
          >
            Servicios
          </Link>
          <Link className="navbar-item" to="/blog" activeClassName="current">
            Noticias
          </Link>
          <Link className="navbar-item" to="/about" activeClassName="current">
            Nosotros
          </Link>
        </div>
        <button
          className={`burger ${this.state.navBarActiveClass}`}
          data-target="navMenu"
          onClick={() => this.toggleHamburger()}
        >
          {/* Hamburger menu */}
          &#x2630;
        </button>
      </nav>
    );
  }
};

export default Navbar;
